<template>
  <div v-show="!isHidden" :style="computedStyle" :class="CSSClasses">
    <el-radio-group
      v-if="type === 'basic' || type === 'button'"
      @change="$emit('input', localModel)"
      :disabled="readonly"
      :name="name"
      :style="this.CSS"
      :size="size"
      v-model="localModel"
    >
      <component
        v-for="(item, index) in localOptions"
        :key="index"
        :label="item.value"
        :is="type === 'basic' ? 'el-radio' : 'el-radio-button'"
      >{{ item.name }}</component>
    </el-radio-group>
    <el-radio-group v-else-if="type === 'lights'" :disabled="readonly" :name="name" :style="this.CSS + ';width:100%'">
      <template v-for="(item, index) in localOptions">
        <el-tooltip
          v-if="item.tooltip"
          class="item"
          effect="dark"
          :key="index"
          :content="item.tooltip"
        >
          <el-button
            circle
            size="mini"
            :style="{background: item.color}"
            @click="localModel = item.value, $emit('input', item.value)"
            :label="item.value"
          ></el-button>
        </el-tooltip>
        <el-button
          v-else
          circle
          size="mini"
          :style="{background: item.color}"
          :key="index"
          @click="localModel = item.value, $emit('input', item.value)"
          :label="item.value"
        ></el-button>
      </template>
    </el-radio-group>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import legacyMixin from '@/components/InterfaceEditor/components/legacyMixin'
export default {
  name: 'a-radio',
  inject: {
    forceUpdateSettingsPanel: {
      default: () => () => {}
    }
  },
  mixins: [mixin, legacyMixin, VisibleMixin],
  props: {
    model: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    defaultValue: {
      type: String,
      description: 'По умолчанию',
      options: {
        tooltip: {
          show: true,
          content: 'Укажите значение из пункта "Опции"'
        }
      }
    },
    name: {
      type: String,
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    width: {
      type: String,
      description: 'Ширина'
    },
    block: {
      type: Boolean,
      description: 'Во всю строку',
      default: true
    },
    type: {
      type: String,
      description: 'Вид',
      default: 'basic',
      editor: 'Select',
      options: {
        options: [
          { id: 'basic', name: 'Стандарт' },
          { id: 'lights', name: 'Светофор' },
          { id: 'button', name: 'Кнопки' }
        ]
      }
    },
    size: {
      type: String,
      description: 'Размер',
      editor: 'Size'
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },
    options: {
      type: Array,
      default () {
        return [{ name: 'Лэйбл', value: 'Значение' }]
      },
      editor: 'Options'
    }
  },
  data () {
    return {
      localModel: this.$attrs.value || this.defaultValue,
      localOptions: this.options
    }
  },
  computed: {
    lights () {
      let lights = false
      this.options.forEach(element => {
        if (element.color) lights = true
      })
      return lights
    },
    computedStyle () {
      let css = ''

      if (!this.block) {
        css += ';display: inline-block'
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }

      return css
    }
  },
  watch: {
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  mounted () {
    if (this.localModel) {
      let parsed = this.localModel

      try {
        parsed = JSON.parse(this.localModel)
      } catch (error) {}
      if (parsed instanceof Array && parsed.length > 0) {
        this.localModel = parsed[0].id + ''
      }
      if (typeof parsed === 'number') {
        this.localModel = parsed + ''
      }
    }
    this.$emit('input', this.localModel)
  }
}
</script>

<style scoped>
</style>
